import Client from "../../base/Client";
import Format from "../../base/Format";
import Helpers from "../../base/Helpers";
import Utility from "../../base/Utility";

class AddressbookAction {
  constructor(addressbook, key, action, params, table, e) {
    this.key = key;
    this.addressbook = addressbook;
    this.event = e || null;
    this.trigger = e?.real_target || e?.target;
    this.action = action;
    this.trueTable = table;
    this.table = table;
    this.form = null;
    this.groupAction = false;
    this.type = table.type;
    this.dialog = null;
    this.givenData = null;
    this.params = params || {};
    this.completed = false;
    this.forwardedAction = null;
    this.trailingDialog = null;
    this.possibleTrigger = null;
    this.init();
  }

  init() {
    this.adaptForGroupAction();

    if (["add", "add-to-group"].includes(this.action)) {
      this.form = window.app_forms?.collection.get(`add-addressbook`) || null;
    } else if (
      ["delete", "delete-all", "remove", "remove-all"].includes(this.action)
    ) {
      this.form =
        window.app_forms?.collection.get(`addressbook-prompt`) || null;
    } else if (["create", "edit"].includes(this.action)) {
      this.form =
        window.app_forms?.collection.get(`addressbook-${this.type}`) || null;
    }
    if (this.form) {
      this.form.onSuccess = this.formSuccessHandler.bind(this);
    }
    if (["delete", "remove"].includes(this.action)) {
      this.possibleTrigger = this.table.getNextRowFocusElement(this.trigger);
    }


    // set dialog instance
    const dialogEl = this.form?.el.closest(".dialog");
    if (dialogEl && window.app_dialogs?.collection.has(dialogEl.id)) {
      this.dialog = window.app_dialogs?.collection.get(dialogEl.id);
    }
    if (this.dialog) {
      this.dialog.onClose = () => {
        this.addressbook.completeAction(this);
      };
    }
    // append real trigger if the triggering element was row click
    if (this.trigger?.classList.contains("table__cell")) {
      this.trigger =
        this.trigger
          .closest("tr")
          ?.querySelector('button[data-action="edit"]') || this.trigger;
    }
  }

  adaptForGroupAction() {
    if (["edit-group", "delete-group"].includes(this.action)) {
      this.groupAction = true;
      this.table = this.addressbook.tables.get("groups") || this.trueTable;
      this.type = this.table.type;
      this.action = this.action.replace("-group", "");
    }
  }

  run() {
    if (this.action == "edit" && this.params.hasOwnProperty("id")) {
      this.edit(this.params.id);
    } else if (this.action == "create") {
      if (this.type == "recipients") {
        this.createRecipient();
      } else {
        this.create();
      }
    } else if (["remove", "remove-all"].includes(this.action)) {
      this.remove();
    } else if (["delete", "delete-all"].includes(this.action)) {
      this.delete();
    } else if ("add" == this.action) {
      if (this.type !== "recipients") {
        this.add();
      } else {
        this.addRecipients();
      }
    } else if ("add-to-group" == this.action) {
      this.addToGroup();
    }
  }

  kill() {
    if (!this.dialog) return;
    this.closeDialog(this.dialog);
  }

  closeDialog(dialog) {
    if (!dialog) return
    let id = this.trigger.getAttribute("data-id");
    if (this.trigger.tagName == "INPUT") {
      this.trigger = this.table?.el.querySelector(
        `input[value="${this.trigger.value}"][name="${this.trigger.name}"]`
      ) || this.trigger;
    } else if (id) {
      this.trigger = this.table?.el.querySelector(
        `[data-action="${this.action}"][data-id="${id}"]`
      ) || this.trigger;
    }
    if (!document.contains(this.trigger)) {
      this.trigger = null;
    }
    this.table.focusEmpty();
    dialog.triggerEl = this.trigger;
    window.app_dialogs?.deactivate(dialog.el.id, this.event);
  }

  delete() {
    if (!this.form) return;
    if (this.params.hasOwnProperty("id")) {
      this.params["ids"] = [this.params.id];
    }
    if (!this.params.hasOwnProperty("ids")) return;
    this.loadDeletePrompt();
    this.openDialog();
  }

  loadDeletePrompt() {
    this.form.el.setAttribute("data-endpoint", `/addressbook/${this.type}s/delete`);
    this.form.requestJSON = true;
    this.form.filterRequest = (data) => {
      return { ids: this.params.ids };
    };
    this.managePromptCopy();
  }

  loadRemovePrompt() {
    this.form.el.setAttribute("data-endpoint", `/addressbook/contacts-group`);
    this.form.requestJSON = true;
    let group = this.getGroupDetails(this.table);
    this.form.filterRequest = (data) => ({
      groups: [group.id],
      contacts: this.params.ids,
      mode: "remove",
    });
    this.managePromptCopy("remove");
  }

  remove() {
    let hasPrompt = this.form ? true : false;
    if (this.params.hasOwnProperty("id")) {
      this.params["ids"] = [this.params.id];
    }
    if (!this.params.hasOwnProperty("ids")) return;
    if (this.type == "recipients") {
      this.removeRecipients();
    } else if (hasPrompt) {
      this.loadRemovePrompt();
      this.openDialog();
    } else {
      this.removeContacts();
    }
  }

  removeRecipients() {
    let target = this.table.getNextRowFocusElement(this.trigger);
    let targetQueryString = '';
    if (target) {
      if (target.tagName == 'INPUT') {
        targetQueryString = `tr input[data-id="${target.getAttribute('data-id')}"]`
      } else if (target.tagName == 'BUTTON') {
        targetQueryString = `tr button[data-id="${target.getAttribute('data-id')}"]`
      }
    }
    if (this.table.el.classList.contains('--table-locked')) {
      let ids = this.params.ids instanceof Array ? this.params.ids : [this.params.ids];
      let recipients = [];
      ids.forEach(id => {
        let number = Number(id);
        recipients.push({ id: number, name: "", email: "" });
      })
      this.table.updateEntry(recipients, true);
    } else {
      this.table.removeEntry(this.params.ids, true);
    }
    if (this.table.entries.length == 0) {
      const id = this.table.generateEntryID(100);
      this.table.addEntry({ id, name: "", email: "" }, true);
    } else {
      if (targetQueryString != '') {
        if (window.app_page?.wasKeyboardEvent) {
          this.table.el.querySelector(targetQueryString)?.focus();
        }
      }
    }
    this.addressbook.completeAction(this);
  }

  createRecipient() {
    const id = this.table.generateEntryID(100);
    this.table.addEntry({ id, name: "", email: "" }, true);
    this.addressbook.completeAction(this);
    window.app_accessibility.announce({
      message: "Recipient row added",
    })
  }

  async removeContacts() {
    let group = this.getGroupDetails(this.table);
    let queryString = [];
    this.params.ids.forEach((id) => {
      queryString.push(`tr[data-id="${id}"], li[data-id="${id}"]`);
    });
    if (queryString.length != 0) {
      const els = this.table.el.querySelectorAll(queryString.join(", "));
      els.forEach((el) => {
        el.classList.add("--loading", "--remove");
      });
    }
    try {
      let response = await fetch("/addressbook/contacts-group", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          groups: [group.id],
          contacts: this.params.ids,
          mode: "remove",
        }),
      });
      let result = await response?.json();
      if (!result?.hasOwnProperty("status")) return;
      if (result.status == "success") {
        this.givenData = result.data;
        this.formSuccessHandler();
      }
    } catch (e) {
      console.warn(e);
      return;
    }
  }

  addRecipients() {
    if (!this.dialog) return;
    let tabs = null;
    const tabsEl = this.dialog.el.querySelector(".tabs");
    if (tabsEl) {
      tabs = window.app_tabs?.collection.get(tabsEl.id);
    }
    const emails = [];
    const hasContacts = this.table.entries.length != 0;
    let selectionLimit = false;
    if (this.table.maxEntries) {
      let populatedCount = 0;
      this.table.entries.forEach(entry => {
        if (entry.name || entry.email) {
          populatedCount++
        }
      })
      selectionLimit = this.table.maxEntries - populatedCount;
    }
    if (this.table.el.classList.contains('--table-locked')) {
      selectionLimit = this.table.entries.length;
    }
    if (hasContacts) {
      this.table.entries.forEach((entry) => {
        if (entry.email != "") {
          emails.push(entry.email);
        }
      });
    }
    this.form.el.setAttribute("data-endpoint", "/send-flow-recipients");

    tabs?.activate("contacts");
    let contactTable = null;
    const innerTableEls = this.dialog.el.querySelectorAll(".table");
    innerTableEls.forEach((innerTableEl) => {
      const table = window.app_tables?.collection.get(innerTableEl.id);
      if (table) {
        table.setSelectionLimit(selectionLimit);
        if (table.tabKey == "contacts") {
          if (!contactTable) {
            contactTable = table;
          }
        }
        if (table.type == "contact") {
          if (emails.length != 0) {
            table.filters.set("exclude_by_key", {
              key: "email",
              values: emails,
            });
          } else {
            table.filters.delete("exclude_by_key");
          }
          table.customEmptyTitle = "There are no contacts";
          table.customFilteredEmptyTitle = "All contacts were added";
        }

        if (table.tabKey == "contacts") {
          table.reloadTable();
        }
        if (table.tabKey == "groups") {
          table.customEmptyTitle = "You have not created any groups";
          table.customFilteredEmptyTitle = "All groups were added";
          const ids = [];
          contactTable?.data?.forEach((entry) => {
            if (emails.includes(entry.email)) {
              ids.push(entry.id);
            }
          });
          table.filterGroupsWithoutIDs(ids);
          table.reloadTable();
        }
      }
    });

    this.form.onSubmit = () => {
      let innerTable = null;
      const innerTableEl = this.dialog.el.querySelector(
        ".tabs__panel.--active .table"
      );
      if (innerTableEl) {
        innerTable = window.app_tables?.collection.get(innerTableEl.id);
      }
      this.injectRecipients(innerTable, contactTable);
      return false;
    };

    Utility.updateCopy(this.dialog.el, {
      title: "Select Contacts",
      submit: "Add Contacts",
    });
    Utility.updateContent({
      submit_btn: {
        "aria-label": "Add Contacts"
      },
    }, this.dialog.el);

    this.openDialog();
  }

  injectRecipients(sourceTable, contactsTable) {
    if (!sourceTable) return;

    let insertionLimit = contactsTable.selectionLimit;
    let recipients = [];
    let uniqueIDs = [];
    sourceTable.selected.forEach((id) => {
      let entry = sourceTable.getEntry(id);
      if (sourceTable.type == "group") {
        entry.contacts.forEach((contact_id) => {
          if (uniqueIDs.includes(contact_id)) return;
          uniqueIDs.push(contact_id);
          let contact = contactsTable.getEntry(contact_id);
          if (contact) {
            recipients.push({
              id: this.table.generateEntryID(contact.id),
              name: Format.getContactName(contact),
              email: contact.email,
            });
          }
        });
      } else {
        recipients.push({
          id: this.table.generateEntryID(entry.id),
          name: Format.getContactName(entry),
          email: entry.email,
        });
      }
    });

    this.table.clearBlanks();

    // Logic for replacing or injecting after
    if (insertionLimit) {
      // Handle replacing and injecting in locked tables
      if (this.table.el.classList.contains('--table-locked') && (insertionLimit - this.table.entries.length < recipients.length)) {
        let toInsertCount = recipients.length;
        let availableCount = insertionLimit - this.table.entries.length;
        let removeRecipients = [];
        let toRemoveCount = toInsertCount - availableCount;
        for (let i = 0; i < toRemoveCount; i++) {
          removeRecipients.push(String(this.table.entries[i].id));
        }
        this.table.removeEntry(removeRecipients, true);
      }
      // Filling recipinets with blanks for locked tables
      if (this.table.el.classList.contains('--table-locked') && this.table.entries.length + recipients.length < insertionLimit) {
        let toFillCount = insertionLimit - (this.table.entries.length + recipients.length);
        for (let i = 0; i < toFillCount; i++) {
          recipients.push({
            id: this.table.generateEntryID(100 + i * 10),
            name: "",
            email: "",
          });
        }
      }
    }
    if (insertionLimit && recipients.length > insertionLimit) {
      this.form.addError(
        "internal",
        `Cannot add ${recipients.length} contacts, only ${insertionLimit} more available for this send`
      );
      this.form.complete("error-server");
      return
    }
    this.table.addEntry(recipients, true);
    this.addressbook.completeAction(this);
    window.app_accessibility?.announce({
      message: `Added ${recipients.length} recipient${recipients.length == 1 ? '' : 's'}`
    });
    this.form.complete("internal");
    setTimeout(() => {
      if (this.dialog) {
        this.closeDialog(this.dialog);
        if (this.trailingDialog) {
          this.closeDialog(this.trailingDialog);
        }
      }
    }, 0)
  }

  add() {
    if (!this.dialog) return;
    this.dialog.el.querySelector(".tabs__control")?.classList.add("hidden");
    let tabs = null;
    const tabsEl = this.dialog.el.querySelector(".tabs");
    if (tabsEl) {
      tabs = window.app_tabs?.collection.get(tabsEl.id);
    }
    const group = this.getGroupDetails(this.table);
    const hasContacts = group.contacts.length != 0;
    tabs?.activate("contacts");
    this.form.el.setAttribute("data-endpoint", "/addressbook/contacts-group");
    let innerTable = null;
    const innerTableEl = this.dialog.el.querySelector(
      ".tabs__panel.--active .table"
    );
    if (innerTableEl) {
      innerTable = window.app_tables?.collection.get(innerTableEl.id);
    }
    if (innerTable) {
      if (hasContacts) {
        innerTable.filters.set("exclude", group.contacts);
      } else {
        innerTable.filters.delete("exclude");
      }
      innerTable.customEmptyTitle = "There are no contacts";
      innerTable.customFilteredEmptyTitle =
        "This group already contains all contacts";
      innerTable.reloadTable();
    }
    this.form.requestJSON = true;
    this.form.filterRequest = (data) => {
      let obj = {
        groups: [group.id],
        contacts: Array.from(innerTable.selected),
        mode: "add",
      };
      return obj;
    };
    Utility.updateCopy(this.dialog.el, {
      title: "Add Contacts",
      submit: "Add Contacts",
    });
    Utility.updateContent({
      submit_btn: {
        "aria-label": "Add Contacts"
      },
    }, this.dialog.el);

    this.openDialog();
  }

  addToGroup() {
    if (!this.dialog) return;
    this.dialog.el.querySelector(".tabs__control")?.classList.add("hidden");
    let tabs = null;
    const tabsEl = this.dialog.el.querySelector(".tabs");
    if (tabsEl) {
      tabs = window.app_tabs?.collection.get(tabsEl.id);
    }
    tabs?.activate("groups");
    this.form.el.setAttribute("data-endpoint", "/addressbook/contacts-group");
    let innerTable = null;
    const innerTableEl = this.dialog.el.querySelector(
      ".tabs__panel.--active .table"
    );
    if (innerTableEl) {
      innerTable = window.app_tables?.collection.get(innerTableEl.id);
    }
    innerTable?.reloadTable();
    this.form.requestJSON = true;
    this.form.filterRequest = (data) => ({
      groups: Array.from(innerTable.selected),
      contacts: this.params.ids,
      mode: "add",
    });
    Utility.updateCopy(this.dialog.el, {
      title: "Add Contacts to Groups",
      submit: "Add to Group",
    });
    Utility.updateContent({
      submit_btn: {
        "aria-label": "Add to Group"
      },
    }, this.dialog.el);

    this.openDialog();
  }

  getGroupDetails(table) {
    const title = table.header?.querySelector('[data-text-key="header-title"]');
    const idEl = table.header?.querySelector('[data-action="edit-group"]');
    let groupID = idEl ? idEl.getAttribute("data-id") : null;
    let contactIDs = [];
    table.entries?.forEach((entry) => contactIDs.push(entry.id));
    let data = {
      id: groupID,
      name: title ? title.textContent.trim() : "unnamed",
      count: this.trueTable.entries.length,
      contacts: contactIDs,
    };
    return data;
  }

  managePromptCopy() {
    let singular = this.params.ids.length == 1;
    const count = singular ? "" : `${this.params.ids.length} `;
    const title = this.form.el.querySelector("h2");
    const paragraph = this.form.el.querySelector("p");
    const primary = this.form.el.querySelector(".btn.--submit");

    let name = "";
    if (this.type == "contact") {
      name = Format.getContactName(this.table.getEntry(this.params.ids[0]));
    } else if (this.type == "group") {
      name = Format.getGroupName(
        this.groupAction
          ? this.getGroupDetails(this.trueTable)
          : this.table.getEntry(this.params.ids[0]),
        true
      );
    }

    const entryLabelMap = {
      contact: singular ? "Contact" : "Contacts",
      group: singular ? "Group" : "Groups",
    };

    let location = "your address book";
    if (this.action.includes("remove")) {
      location = `<strong data-cs-mask>${Format.getGroupName(
        this.getGroupDetails(this.trueTable)
      )}</strong>`;
    }

    const entryLabel = entryLabelMap[this.type];

    const copyMap = {
      remove: {
        title: `Remove ${entryLabel}?`,
        paragraph: `You are about to remove <strong data-cs-mask>${singular ? name : count + entryLabel.toLowerCase()
          }</strong> from ${location}`,
        primary: `Remove <span class="hidden-xs">${count + entryLabel}</span>`,
        primary_label: `Remove ${count + entryLabel}`
      },
      delete: {
        title: `Delete ${entryLabel}?`,
        paragraph: `You are about to delete <strong data-cs-mask>${singular ? name : count + entryLabel.toLowerCase()
          }</strong> from ${location}`,
        primary: `Delete <span class="hidden-xs">${count + entryLabel}</span>`,
        primary_label: `Delete ${count + entryLabel}`
      },
    };

    if (!copyMap.hasOwnProperty(this.action.replace("-all", ""))) return;
    const copy = copyMap[this.action.replace("-all", "")];
    if (title) title.innerHTML = copy.title;
    if (paragraph) paragraph.innerHTML = copy.paragraph;
    if (primary) {
      primary.setAttribute("aria-label", copy.primary_label);
      const text = primary.querySelector(".btn__text");
      if (text) text.innerHTML = copy.primary;
    }
  }

  edit(id) {
    if (!this.form) return;
    let data = {};
    if (this.groupAction) {
      data = this.getGroupDetails(this.trueTable);
    } else {
      data = this.table.getEntry(id);
    }

    if (this.type == "contact") {
      this.loadContactForm(data);
    } else if (this.type == "group") {
      this.loadGroupForm(data);
    }
    if (this.dialog) {
      this.dialog.onClick = (e) => {
        if (e.target.classList.contains("--delete-action")) {
          this.addressbook.initAction(
            this.groupAction ? "delete-group" : "delete",
            this.params,
            this.trueTable,
            this.event
          );
        }
      };
      this.dialog.onClose = () => {
        this.addressbook.completeAction(this);
        this.dialog.onClick = (e) => { };
        setTimeout(() => {
          if (!this.dialog.isActive) {
            this.form.disableTransition();
            this.form?.clear(true, true);
            setTimeout(() => {
              this.form?.enableTransition();
            }, 10);
          }
        }, 400);
      };
    }
    this.openDialog();
  }

  create() {
    this.addressbook.main.actions.forEach((action) => {
      if (this.forwardedAction) return;
      if (
        action.key != this.key &&
        ["add-to-group", "add"].includes(action.action)
      ) {
        this.forwardedAction = action;
      }
    });
    if (!this.form) return;

    if (this.type == "contact") {
      this.loadContactForm();
    } else if (this.type == "group") {
      this.loadGroupForm();
    }
    if (this.dialog) {
      this.dialog.onClose = () => {
        this.addressbook.completeAction(this);
        setTimeout(() => {
          if (!this.dialog.isActive) {
            this.form.disableTransition();
            this.form?.clear(true, true);
            setTimeout(() => {
              this.form?.enableTransition();
            }, 10);
          }
        }, 300);
      };
    }
    this.openDialog();
  }

  openDialog() {
    if (this.dialog) {
      setTimeout(() => {
        window.app_dialogs?.activate(
          this.dialog.el.id,
          this.trigger,
          this.event
        );
      }, 0);
    }
  }

  updateGroupDetails(entry) {
    const title = this.trueTable.header?.querySelector(
      '[data-text-key="header-title"]'
    );
    if (title) {
      title.innerHTML = entry.name;
    }
  }

  formSuccessHandler() {
    this.completed = true;
    if (["create", "edit"].includes(this.action)) {
      let data = this.givenData
        ? this.givenData
        : this.form.lastRequest.response.data;
      let entry = data && data.hasOwnProperty("entry") ? data.entry : null;
      if (entry) {
        if (this.type == "contact") {
          window.dispatchEvent(new Event('addressbook-changed'));
          let oldContact = this.table.getEntry(entry.id);
          let changes = Helpers.getContactChanges(entry, oldContact);
          changes && window.app_services?.track(oldContact ? "contact-updated" : "contact-added", changes);
        }
        if (this.action == "edit") {
          this.table?.updateEntry(entry, true);
          window.app_tables?.collection.forEach((table) => {
            if (
              table.type == this.table.type &&
              this.table.el.id !== table.el.id
            ) {
              table.updateEntry(entry);
            }
          });
          if (this.groupAction) {
            this.updateGroupDetails(entry);
          }
        } else {
          this.table?.addEntry(entry, true);
          window.app_tables?.collection.forEach((table) => {
            if (
              table.type == this.table.type &&
              this.table.el.id !== table.el.id
            ) {
              table.addEntry(entry);
            }
          });
          if (
            this.action == "create" &&
            this.type == "group" &&
            !this.addressbook.dialog
          ) {
            setTimeout(() => {
              this.addressbook.initGroupDetails({ id: entry.id }, this.table)
            }, 1000);
          }
        }
      }
    } else if (
      ["remove", "delete", "remove-all", "delete-all"].includes(this.action)
    ) {
      this.table?.removeEntry(this.params.ids, true);
      if (["delete", "remove"].includes(this.action)) {
        this.trigger = this.possibleTrigger || this.trigger;
      }
      if (["delete", "delete-all"].includes(this.action)) {
        if (this.type == "contact") {
          window.app_services?.track("contact-removed", {});
        }
        window.app_tables?.collection.forEach((table) => {
          if (
            table.type == this.table.type &&
            this.table.el.id !== table.el.id
          ) {
            table.removeEntry(this.params.ids);
          }
        });
      }
      if (this.groupAction) {
        setTimeout(() => {
          this.addressbook.tabs?.activate("groups");
        }, 0);
        if (window.app_page?.wasKeyboardEvent) {
          setTimeout(() => {
            window.app_accessibility.focus(this.addressbook.tabs.getActivePanel());
          }, 300);
        }
      } else if (
        ["delete", "delete-all"].includes(this.action) &&
        this.type == "contact"
      ) {
        this.addressbook.deleteGroupContacts(this.params.ids);
      }
      if (["remove", "remove-all"].includes(this.action)) {
        this.addressbook.removeGroupContacts(this.params.ids, [
          String(this.getGroupDetails(this.table).id),
        ]);
      }
    } else if (["add", "add-to-group"].includes(this.action)) {
      const ids = [];
      const groups = [];
      this.form.lastRequest.request?.contacts?.forEach((id) => {
        ids.push(String(id));
      });
      this.form.lastRequest.request?.groups?.forEach((id) => {
        groups.push(String(id));
      });
      if (this.action == "add") {
        let group = this.getGroupDetails(this.table);
        this.table.filters.set("include", [...group.contacts, ...ids]);
        this.table.el.setAttribute(
          "data-get",
          this.table.el.classList.contains("--is-send")
            ? "/addressbook/contacts?email=1"
            : "/addressbook/contacts?events=1"
        );
      }
      this.addressbook.addGroupContacts(ids, groups);
      this.table.selectAll(false);
    }

    if (this.forwardedAction) {
      setTimeout(() => {
        this.handleForwardedAction();
      }, 0);
    } else if (this.dialog) {
      this.closeDialog(this.dialog);
      if (this.trailingDialog) {
        this.closeDialog(this.trailingDialog);
      }
    }
    return false;
  }

  handleForwardedAction() {
    if (this.action == "create" && this.forwardedAction) {
      const params = this.forwardedAction.params;
      const table = this.forwardedAction.table;
      const form = this.forwardedAction.form;
      this.forwardedAction.trailingDialog = this.dialog;
      let data = this.givenData
        ? this.givenData
        : this.form.lastRequest.response.data;
      let entry = data && data.hasOwnProperty("entry") ? data.entry : null;
      if (entry) {
        form.el.setAttribute("data-endpoint", "/addressbook/contacts-group");

        if (this.type == "group") {
          form.filterRequest = (data) => ({
            groups: [String(entry.id)],
            contacts: params.ids,
            mode: "add",
          });
        } else if (this.type == "contact") {
          const group = this.getGroupDetails(table);
          form.filterRequest = (data) => ({
            groups: [String(group.id)],
            contacts: [String(entry.id)],
            mode: "add",
          });
        }

        this.forwardedAction = null;

        form.abortController = new AbortController();
        form.submitHandler(null, null, null, false, true);
      }
    }
  }

  setDefaultCheckboxes() {
    this.form?.conditionalFieldsets.forEach((conditional) => {
      let checkDefault = false;
      conditional.fieldsets.forEach((fieldset) => {
        if (fieldset.sourceValue != "checked") return;
        fieldset.innerFields.forEach((field) => {
          if (field.truename.includes("month") && field.el.value == "") {
            checkDefault = true;
          }
        });
      });
      if (checkDefault) {
        let checkbox = conditional.el.querySelector(
          'input[type="checkbox"][value="-86400"]'
        );
        if (checkbox) {
          checkbox.checked = true;
          checkbox.setAttribute("checked", true);
        }
      }
    });
  }

  loadContactForm(data = {}) {
    if (!this.form) return;
    this.form.disableTransition();
    this.form.disableValidation();
    this.form.clear(true, true);
    setTimeout(() => {
      this.form.fill(Format.contactToForm(data));
    }, 0);
    setTimeout(() => {
      this.form?.enableValidation();
      this.form?.enableTransition();
      this.setDefaultCheckboxes();
    }, 200);
    this.form.el.setAttribute("data-endpoint", `/addressbook/contact`);

    this.form.requestJSON = true;
    this.form.filterRequest = Format.formToContact;
    this.manageFormCopy();
  }

  loadGroupForm(data = {}) {
    if (!this.form) return;
    this.form.clear(true);
    this.form.fill(data);
    this.form.el.setAttribute("data-endpoint", `/addressbook/group`);
    this.form.requestJSON = true;
    this.form.filterResponse = (data) => data;
    if (this.groupAction && this.action == "edit") {
      const group = this.getGroupDetails(this.trueTable);
      this.form.filterResponse = (data) => {
        data.data.entry["contacts"] = group.contacts;
        data.data.entry["count"] = group.count;
        return data;
      };
    }
    this.manageFormCopy();
  }

  manageFormCopy() {
    let isEdit = this.action != "create";
    const title = this.form.el.querySelector("h2");
    const primary = this.form.el.querySelector(".btn.--submit");
    const secondary = this.form.el.querySelector(".btn.--secondary");

    const copyMap = (string = "") => ({
      contact: {
        title: isEdit ? "Edit Contact" : "Add New Contact",
        primary: isEdit
          ? `Update <span class="hidden-xs">Contact</span>`
          : `Create <span class="hidden-xs">Contact</span>`,
        primary_label: isEdit ? `Update Contact` : `Create Contact`,
        secondary: isEdit ? "Delete" : "Cancel",
        hasDelete: isEdit,
      },
      group: {
        title: isEdit ? "Edit Group" : "Add New Group",
        primary: isEdit
          ? `Update <span class="hidden-xs">Group</span>`
          : `Create <span class="hidden-xs">Group</span>`,
        primary_label: isEdit ? `Update Group` : `Create Group`,
        secondary: isEdit ? "Delete" : "Cancel",
        hasDelete: isEdit,
      },
      "contact-add": {
        title: "Add New Contact to Group",
        primary: `Create & Add`,
        secondary: "Back",
        hasDelete: false,
      },
      "group-add": {
        title: `Add ${string} to a New Group`,
        primary: `Create & Add`,
        secondary: "Back",
        hasDelete: false,
      },
    });

    if (!copyMap().hasOwnProperty(this.type)) return;
    let copy = copyMap()[this.type];
    if (this.forwardedAction) {
      this.form.persistLoading = true;
      this.forwardedAction.dialog?.hideContent();
      this.dialog?.hideBackdrop();
      let string = "";
      if (this.forwardedAction.action == "add-to-group") {
        const count = this.forwardedAction.params.ids.length;
        string = count == 1 ? "1 Contact" : `${count} Contacts`;
      }
      copy = copyMap(string)[`${this.type}-add`];
    }

    if (title) title.textContent = copy.title;
    if (primary) {
      primary.setAttribute("aria-label", copy.primary_label || copy.primary);
      let text = primary.querySelector(".btn__text");
      if (text) {
        text.innerHTML = copy.primary;
      }
    }

    if (secondary) {
      secondary.setAttribute("aria-label", copy.secondary);
      let text = secondary.querySelector(".btn__text");
      if (text) {
        text.textContent = copy.secondary;
      }
      if (copy.hasDelete) {
        secondary.classList.remove("dialog__close");
        secondary.classList.add("--delete-action", "--red");
        secondary.setAttribute("aria-haspopup", "dialog");
      } else {
        secondary.classList.add("dialog__close");
        secondary.classList.remove("--delete-action", "--red");
        secondary.removeAttribute("aria-haspopup");
      }
    }
  }
}

export default AddressbookAction;
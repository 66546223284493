import Render from "../base/Render";
import Client from "../base/Client";
import Utility from "../base/Utility";
import DynamicHeight from "../utilities/DynamicHeight";

class Table {
  constructor(el, parent) {
    this.el = el;
    this.parent = parent;
    this.addressbook = null;
    this.isLoading = false;
    this.tabs = null;
    this.proxies = {};
    this.tabKey = null;
    this.form = null;
    this.dialog = null;
    this.data = null;
    this.tab = null;
    this.key = this.el.getAttribute("data-key");
    this.renderedItemsCount = 0;
    this.abortController = null;
    this.scrollTimeout = null;
    this.hasTableInputs = false;
    this.blockRender = false;
    this.entries = null;
    this.filters = new Map();
    this.originalEntries = null;
    this.entriesCount = false;
    this.selectionLimit = false;
    this.maxEntries = Number(el.getAttribute("data-max-entries") || 0);
    this.timestamp = el.getAttribute('data-timestamp');
    this.entryObserver = new IntersectionObserver(
      this.entryObserverHandler.bind(this),
      { rootMargin: "150px 0px 150px 0px" }
    );
    this.header = el.querySelector(".table-header") || null;
    this.reloadPending = false;
    this.dataReadyJSON = el.querySelector(".table__data-ready") || null;
    this.wasFiltered = false;
    this.getIsForceLoad = () => this.el.hasAttribute("data-force-load");
    this.getIsRendered = () => this.el.classList.contains("--table-rendered");
    this.labels = {
      singular: el.getAttribute("data-singular") || "Contact",
      plural:
        el.getAttribute("data-plural") || el.hasAttribute("data-singular")
          ? `${el.getAttribute("data-singular")}s`
          : "Contacts",
    };
    this.announceSearchResults = false;
    this.sortedEntries = null;
    this.isFetching = false;
    this.type = el.hasAttribute("data-type")
      ? el.getAttribute("data-type")
      : "contact";
    this.tableEl = el.querySelector(".table__element");
    this.tableLabel = this.tableEl.getAttribute("aria-label");
    this.tableBody = el.querySelector("table tbody");
    this.listBody = el.querySelector(".table__list-content");
    this.sortEl =
      this.el.querySelector(".table__sort-trigger.--active") || null;
    this.sortOrder = this.sortEl
      ? this.sortEl.value == "ascending"
        ? "descending"
        : "ascending"
      : "ascending";
    this.sortBy = this.sortEl ? this.sortEl.name : "";
    this.searchBy = "";
    this.selected = new Set();
    this.fields = new Map();
    this.checkboxes = new Map();
    this.searcheables = new Set();
    this.isSearching = false;
    this.pages = 1;
    this.endpoint = el.hasAttribute("data-get")
      ? el.getAttribute("data-get")
      : null;
    this.originalEndpoint = el.hasAttribute("data-get")
      ? el.getAttribute("data-get")
      : null;
    this.currentPage = el.hasAttribute("data-current-page")
      ? Number(el.getAttribute("data-current-page"))
      : 1;
    this.searchResultsCount = 0;
    this.announcerTimeout = null;
    this.intersectionObserver = null;
    this.WAIannouncerTimeout = null;
    this.templates = {
      row: Render.getTemplateString(this.el, "table-row"),
      item: Render.getTemplateString(this.el, "table-item"),
    };
    this.pagination = {
      holder: this.el.querySelector(".table-dynamic-pagination"),
      nextBtn: this.el.querySelector(`[data-action="next-page"]`),
      prevBtn: this.el.querySelector(`[data-action="prev-page"]`),
      isPaginable: this.el.hasAttribute("data-entries-per-page"),
      perPage: Number(this.el.getAttribute("data-entries-per-page")) || null,
      currentPage: 1,
      totalPages: Number(this.el.getAttribute("data-total-pages")) || 1,
      totalEntries: this.entries?.length || this.entriesCount || 0,
    };
    this.customEmptyTitle = null;
    this.customFilteredEmptyTitle = null;
    this.searchRegEx = (term) =>
      new RegExp(term.replace(new RegExp(/\W/, "gi"), match => `\\${match}`), "gi");
    // Lifecycle Hooks
    this.onReady = (instance) => { };
    this.onChange = (instance) => { };
    this.onFilter = () => { };
    this.onSearch = (term, count) => { };
    this.onSort = (name, order) => { };
    this.onSelect = (selected) => { };
    this.onClick = (e, action, params = null, instance) => { };
    this.init();
  }

  get isSendflow() {
    return this.el.classList.contains("--is-send")
  }

  init() {
    const addressbookEl = this.el.closest(".addressbook");
    const formEl = this.el.closest(".form");
    const tabsEl = this.el.closest(".tabs");
    const dialogEl = this.el.closest(".dialog");
    if (addressbookEl) {
      this.addressbook = window.app_addressbooks?.collection.get(
        addressbookEl.id
      );
      this.form = this.addressbook?.form;
      this.tabs = this.addressbook?.tabs;
    }
    if (!this.form && formEl) {
      this.form = window.app_forms?.collection.get(formEl.id);
    }
    if (!this.tabs && tabsEl) {
      this.tabs = window.app_tabs?.collection.get(tabsEl.id);
    }
    if (dialogEl) {
      this.dialog = window.app_dialogs?.collection.get(dialogEl.id);
    }

    Utility.addWidthObserver(this.tableEl, "--table-width");

    const tab = this.el.closest('[role="tabpanel"]');
    if (this.tabs && tab) {
      this.tab = tab;
      this.tabKey = this.tab.getAttribute("data-name");
      if (this.dialog) {
        this.dialog.el.addEventListener(
          "dialog-opened",
          this.onDialogOpen.bind(this)
        );
        this.dialog.el.addEventListener(
          "dialog-closed",
          this.onDialogClose.bind(this)
        );
      } else {
        this.tab.addEventListener("tab-activated", this.onTabActive.bind(this));
        this.tab.addEventListener(
          "tab-deactivated",
          this.onTabInactive.bind(this)
        );
        if (this.tabs.activeKey == this.tabKey) {
          this.loadTable();
        }
      }
    } else {
      this.loadTable();
    }

    const searcheables = this.el.querySelectorAll(
      "table th[data-searcheable], table td[data-searcheable]"
    );
    searcheables.forEach((searcheable) => {
      if (searcheable.hasAttribute("data-name")) {
        this.searcheables.add(searcheable.getAttribute("data-name"));
      }
    });
    this.initDataMutation();
    this.assignFields();
    this.el.classList.add("--init");
  }

  onDialogOpen() {
    this.proxies["tab-activated"] = this.onTabActive.bind(this);
    this.proxies["tab-deactivated"] = this.onTabInactive.bind(this);
    setTimeout(() => {
      if (this.dialog.isActive) {
        this.tab.addEventListener(
          "tab-activated",
          this.proxies["tab-activated"]
        );
        this.tab.addEventListener(
          "tab-deactivated",
          this.proxies["tab-deactivated"]
        );
        if (this.tabs.activeKey == this.tabKey) {
          if (!this.isLoading) {
            this.loadTable();
          }
        }
      }
    }, 300);
  }

  generateEntryID(seed) {
    let entry_ids = [];
    let result = seed;
    this.entries?.forEach((entry) => {
      if (entry.hasOwnProperty("id")) {
        entry_ids.push(Number(entry.id));
      }
    });

    function testDuplicates(i) {
      if (!entry_ids.includes(i)) {
        return i;
      } else {
        return testDuplicates(i + 1);
      }
    }

    result = testDuplicates(seed);
    return result;
  }

  onDialogClose() {
    if (this.tabs.activeKey == this.tabKey) {
      setTimeout(() => {
        this.clearUserInput();
      }, 200);
    }
    this.tab.removeEventListener(
      "tab-activated",
      this.proxies["tab-activated"]
    );
    this.tab.removeEventListener(
      "tab-deactivated",
      this.proxies["tab-deactivated"]
    );
  }

  onTabActive() {
    this.checkEmpty();
    if (!this.isLoading) {
      this.loadTable(false);
    }
  }

  onTabInactive() {
    this.clearUserInput();
  }

  clearUserInput() {
    this.selectAll(false, true);
    this.fields.forEach((field, key) => {
      if (key.includes("search")) {
        field.setValue("", true);
      }
    });
  }

  fillHeader(data = {}) {
    if (!this.header) return;
    const slots = this.header.querySelectorAll("[data-text-key]");
    slots.forEach((slot) => {
      const key = slot.getAttribute("data-text-key");
      if (data.hasOwnProperty(key)) {
        slot.innerHTML = data[key];
      } else {
        slot.innerHTML = "";
      }
    });
    this.tab.setAttribute("aria-label", `${data["header-title"]} Group Details`);
    const actions = this.header.querySelectorAll("[data-action]");
    actions.forEach((action) => {
      if (action.hasAttribute("data-id") && data.hasOwnProperty("id")) {
        action.setAttribute("data-id", data.id);
      }
    });
  }

  loadTable(init = true) {
    this.isLoading = true;
    if (this.getIsRendered() && !this.entries) {
      this.blockRender = true;
      this.dataRequest();
    } else if (!this.getIsRendered()) {
      this.dataRequest();
    } else if ((this.getIsForceLoad() || this.reloadPending) && !init) {
      this.reloadPending = false;
      this.el.classList.add("--table-loading");
      this.dataRequest();
    } else {
      this.tableReady();
    }
  }

  initDataMutation() {
    const observer = new MutationObserver(this.onMutate.bind(this));
    observer.observe(this.el, { attributes: true });
  }

  onMutate(mutationList) {
    mutationList.forEach((mutation) => {
      if (mutation.type !== "attributes") return;
      if (mutation.attributeName == "data-get") {
        this.reloadTable();
      }
    });
  }

  reloadTable() {
    if (!this.parent.getSource(this.el.getAttribute("data-get"))) {
      this.el.classList.add("--table-loading");
      this.el.classList.remove("--table-rendered");
      this.el.classList.remove("--no-entries");
      this.dialog?.el.classList.remove("--no-entries");
      if (this.entriesCount || this.entriesCount === 0) {
        if (this.entriesCount == 0) {
          this.dialog?.el.classList.add("--no-entries");
          this.el.classList.add("--no-entries");
          this.el.classList.remove("--table-loading");
        }
        let templateEntries = [];
        for (let i = 0; i < this.entriesCount; i++) {
          templateEntries.push({});
        }
        this.renderItems(templateEntries, false);
      }
    }
    setTimeout(() => this.dataRequest(), 0);
  }

  dataRequest() {
    if (this.dataReadyJSON) {
      this.dataLoad(JSON.parse(this.dataReadyJSON.textContent));
    } else if (this.el.hasAttribute("data-get")) {
      let data = this.parent.getSource(this.el.getAttribute("data-get"));
      if (data) {
        this.endpoint = this.el.getAttribute("data-get");
        this.dataLoad(data);
        return;
      }
      if (this.data && this.endpoint == this.el.getAttribute("data-get")) {
        this.dataLoad(this.data);
        return;
      }

      this.endpoint = this.el.getAttribute("data-get");
      this.isFetching = true;
      if (this.tabs) {
        if (this.dialog) {
          setTimeout(() => {
            if (this.isFetching && this.entriesCount === false) {
              DynamicHeight.start(this.tabs.container);
            }
          }, 300);
        } else {
          if (this.entriesCount === false) {
            DynamicHeight.start(this.tabs.container);
          }
        }
      }
      fetch(this.endpoint)
        .then((response) => response.json())
        .then((data) => {
          this.dataLoad(data);
        })
        .catch((e) => {
          console.warn(e);
        });
    }
  }

  dataLoad(data) {
    if (!data) return;
    if (data instanceof Array) {
      this.data = data;
      this.entries = data;
    } else {
      this.data = data;
      if (data.hasOwnProperty("pages")) {
        this.pages = Number(data["pages"]);
      }
      if (data.hasOwnProperty("entries")) {
        this.entries = data["entries"];
      } else if (data.hasOwnProperty("results")) {
        this.entries = data["results"];
      } else if (data.hasOwnProperty("contacts")) {
        this.entries = data["contacts"];
      }
    }
    this.dataReadyJSON?.remove();
    this.dataReadyJSON = null;
    if (this.endpoint) {
      this.parent.addSource(this.endpoint, this.data, false);
    }
    this.dataReady();
  }

  filterEntries() {
    if (!this.entries) return;
    let entries = [...this.entries];
    if (this.filters.size != 0) {
      this.filters.forEach((value, key) => {
        value = key == "exclude_by_key" ? value : String(value);
        if (key == "include") {
          entries = entries.filter((item) => value.includes(String(item.id)));
        } else if (key == "exclude") {
          entries = entries.filter((item) => !value.includes(String(item.id)));
        } else if (key == "exclude_by_key") {
          entries = entries.filter(
            (item) => !value.values.includes(String(item[value.key]))
          );
        }
      });
      this.wasFiltered = true;
    }
    this.entries = [...entries];
    this.originalEntries = [...entries];
  }

  filterGroupsWithoutIDs(contactIDs) {
    if (!this.entries) return;
    let filteredIDs = [];
    let entries = [...this.data];
    let newEntries = [];
    entries.forEach((item) => {
      let contacts = item.contacts.filter(id => !contactIDs.includes(id));
      item.count = contacts.length;
      if (item.count == 0) {
        filteredIDs.push(item.id)
      }
      newEntries.push(item)
    })
    this.filters.set('exclude', filteredIDs);
    this.entries = [...newEntries];
    this.originalEntries = [...newEntries];
  }

  dataReady() {
    if (!this.entries) return;
    this.isLoading = false;
    this.filterEntries();
    this.sortedEntries = this.entries;
    this.pagination.totalEntries = this.entries.length;
    this.changeDynamicPage(1);
    this.checkEmpty();
    if (this.blockRender && !this.isFetching && !this.wasFiltered) {
      this.blockRender = false;
      this.initTable();
    } else {
      this.wasFiltered = false;
      this.sort();
      this.renderPagination();
    }
  }

  assignFields() {
    if (!this.form) return;
    this.checkboxes = new Map();
    this.fields = new Map();
    this.form.fields.forEach((field, key) => {
      if (this.el.contains(field.el)) {
        if (field.type == "boolean") {
          if (field.truename.includes("select")) {
            this.checkboxes.set(key, field);
          }
        } else {
          this.fields.set(key, field);
        }
      }
    });
    this.initFilters();
    this.initCheckboxes();
  }

  initFilters() {
    this.fields.forEach((field, key) => {
      if (field.hasOwnProperty("onSelect")) {
        if (key.includes("sort_by")) {
          field.setValue(this.sortBy);
          field.onSelect = this.sortHandler.bind(this);
        } else if (key.includes("sort_order")) {
          field.setValue(this.sortOrder);
          field.onSelect = this.sortHandler.bind(this);
        } else if (key.includes("page")) {
          field.onSelect = (label, value) => {
            this.changeDynamicPage(value);
          };
        }
      }
      if (key.includes("search")) {
        field.onInput = this.searchHandler.bind(this);
      }
    });
  }

  changeDynamicPage(page, render = true) {
    if (!this.pagination.perPage) return
    if (this.pagination.currentPage == Number(page)) return this.handleDynamicPageChange()
    this.pagination.currentPage = Number(page);
    if (this.fields.get(`${this.key}_page`).getValue() != page) {
      this.fields.get(`${this.key}_page`).setValue(page);
    }
    if (render) {
      this.renderItems(this.sortedEntries);
    }
    this.handleDynamicPageChange(true);
  }

  handleDynamicPageChange(pageChanged = false) {
    let totalPages = Math.ceil(this.pagination.totalEntries / this.pagination.perPage);
    if (totalPages)
    if (this.pagination.totalPages != totalPages) {
      this.pagination.totalPages = totalPages;
      let pageString = `range:1-${totalPages}`;
      let pageField = this.form?.fields?.get(`${this.key}_page`);
      if (pageField && pageField.el.getAttribute("data-ready") != pageString) {
        if (Number(pageField.getValue()) > totalPages) {
          pageField.setValue(totalPages);
        }
        pageField.el.setAttribute("data-ready", pageString);
      }
    }
    let isFirstPage = this.pagination.currentPage == 1;
    let isLastPage = this.pagination.currentPage == this.pagination.totalPages;
    let isPaginated = this.pagination.totalPages > 1;
    let pageDetail = isPaginated ? `Page ${this.pagination.currentPage}: ` : "";
    let tableLabel = isPaginated ? `${this.tableLabel}, page ${this.pagination.currentPage}` : this.tableLabel;
    this.tableEl.setAttribute("aria-label", tableLabel);
    Utility.updateContent({
      select_all_label: `${pageDetail}Select All ${this.labels.plural}`,
      mobile_select_all_label: `${pageDetail}Select All <span class="sr-only">${this.labels.plural}</span>`,
    }, this.el)
    if (isLastPage) {
      this.pagination.nextBtn?.setAttribute("aria-disabled", true);
    } else {
      this.pagination.nextBtn?.removeAttribute("aria-disabled");
    }
    if (isFirstPage) {
      this.pagination.prevBtn?.setAttribute("aria-disabled", true);
    } else {
      this.pagination.prevBtn?.removeAttribute("aria-disabled");
    }
    this.pagination.holder?.classList.toggle("hidden", !isPaginated);
    if (pageChanged) {
      this.afterDynamicPageChange();
    }
  }

  afterDynamicPageChange() {
    clearTimeout(this.scrollTimeout);
    setTimeout(() => {
      window.app_page.scrolled = false;
    })
    this.scrollTimeout = setTimeout(() => {
      !window.app_page.scrolled && this.scrollToTop();
      if (!window?.app_page.wasKeyboardEvent) return
      setTimeout(() => {
        if (!this.focusEmpty()) {
          window.app_accessibility?.focus(this.el.querySelector(".table__wrapper"));
        }
      }, 100)
    }, 500)
  }

  searchHandler(value) {
    if (this.originalEntries) {
      this.entries = [...this.originalEntries];
    }
    this.searchBy = value;
    this.search();
  }

  sortHandler() {
    let sortBy = "email";
    let sortByLabel = "email";
    let sortOrder = "ascending";
    this.fields.forEach((field, key) => {
      if (key.includes("sort_by")) {
        sortBy = field.getValue();
        sortByLabel = field.getLabel().replace("Sort by ", "");
      } else if (key.includes("sort_order")) {
        sortOrder = field.getValue();
      }
    });
    window.app_accessibility?.speak(
      `Sorted ${this.labels.plural} by ${sortByLabel} ${sortOrder}`,
      "assertive"
    );
    this.sort(sortBy, sortOrder);
  }

  initCheckboxes() {
    this.checkboxes.forEach((checkbox, key) => {
      checkbox.onCheck = this.checkHandler.bind(this);
      checkbox.onUncheck = this.uncheckHandler.bind(this);
    });
  }

  selectAll(select = true, total = false) {
    this.checkboxes.forEach((checkbox) => {
      if (checkbox.getIsGroup()) {
        checkbox.getGroup().forEach((el) => {
          this.setCheckboxState(el, select);
        });
      } else {
        this.setCheckboxState(checkbox.el, select);
      }
    });
    if (!select && total) {
      this.selected = new Set();
    }
    this.selectHandler();
  }

  setCheckboxState(el, select, update = true) {
    el.checked = select;
    update = el.value == "all" ? false : update;
    if (select) {
      el.setAttribute("checked", "checked");
      if (update) this.selected.add(el.value);
    } else {
      el.removeAttribute("checked");
      if (update) this.selected.delete(el.value);
    }
  }

  select(id) {
    if (id != "all") {
      this.selected.add(String(id));
    }
    this.selectHandler();
  }

  deselect(id) {
    if (id != "all") {
      this.selected.delete(String(id));
    }
    this.selectHandler();
  }

  selectHandler() {
    let entriesCount = 0;
    let selectedEntries = new Set();
    this.checkboxes.forEach((checkbox) => {
      if (checkbox.getIsGroup()) {
        let group = checkbox.getGroup();
        entriesCount = group.length - 1;
        group.forEach((el) => {
          if (this.selected.has(el.value)) {
            selectedEntries.add(el.value)
          }
          this.setCheckboxState(el, this.selected.has(el.value), false);
        });
      } else {
        if (this.selected.has(checkbox.el.value)) {
          selectedEntries.add(checkbox.el.value)
        }
        this.setCheckboxState(
          checkbox.el,
          this.selected.has(checkbox.el.value),
          false
        );
      }
    });
    this.checkboxes.forEach(checkbox => {
      this.setCheckboxState(
        checkbox.el,
        this.selected.size != 0 &&
        selectedEntries.size == entriesCount,
        false
      );
    })

    this.manageSelected();
    this.onSelect(this.selected);
  }

  manageSelected() {
    if (this.selectionLimit && this.form.errors.has("internal")) {
      this.form.removeErrors();
    }
    const selectedEls = this.el.querySelectorAll(".table__selected");
    const actionContainers = this.el.querySelectorAll(
      ".table__selected-actions, .table__selected-actions, .table__clear-action"
    );
    this.setActionLabels();
    if (this.selected.size == 0) {
      this.el.classList.remove("--selected");
      setTimeout(() => {
        if (this.selected.size == 0)
          selectedEls.forEach((el) => {
            el.innerHTML = "";
          });
      }, 200);
      actionContainers.forEach((el) => el.setAttribute("aria-hidden", true));
    } else {
      this.el.classList.add("--selected");
      selectedEls.forEach((el) => {
        el.innerHTML = `${this.selected.size} <span class="sr-only">${this.selected.size == 1 ? this.labels.singular : this.labels.plural
          }&nbsp;</span> selected`;
      });
      actionContainers.forEach((el) => el.setAttribute("aria-hidden", false));
    }
    if (this.dialog && this.tabs && this.tabs.activeKey == this.tabKey) {
      let action = this.tabKey == "groups" && !this.isSendflow ? "Add to" : "Add";
      let ctaText = `${action} ${this.labels.plural}`;
      if (this.selected.size == 1) {
        ctaText = `${action} 1 ${this.labels.singular}`;
      } else if (this.selected.size != 0) {
        ctaText = `${action} ${this.selected.size} ${this.labels.plural}`;
      }
      Utility.updateCopy(this.dialog.el, {
        submit: ctaText,
      });
      Utility.updateContent({
        submit_btn: {
          "aria-label": ctaText
        },
      }, this.dialog.el);
    }
  }

  getNextRowFocusElement(initiatorElement) {
    let result = null;
    let row = initiatorElement.closest('tr');
    if (row) {
      result = row.nextElementSibling?.querySelector('input', 'button', 'a');
      if (!result) {
        result = row.previousElementSibling?.querySelector('input', 'button', 'a');
      }
    }
    return result;
  }

  setActionLabels() {
    const actions = this.el.querySelectorAll("button[data-action]");
    let map = {};
    if (this.selected.size != 0) {
      map = {
        count: this.selected.size,
        entry:
          this.selected.size == 1 ? this.labels.singular : this.labels.plural,
      };
    }
    actions.forEach((action) => {
      if (action.hasAttribute("data-aria-template")) {
        let label = Render.interpolateString(
          action.getAttribute("data-aria-template"), map, true);
        action.setAttribute("aria-label", label);
      }
      if (action.hasAttribute("data-label-template") && this.selected.size != 0) {
        let text = Render.interpolateString(
          action.getAttribute("data-label-template"), map, true);
        action.textContent = text;
      }
    });
  }

  checkHandler(el, checkbox) {
    if (el.value == "all") {
      this.selectAll();
    } else {
      this.select(el.value);
    }
  }

  uncheckHandler(el, checkbox) {
    if (el.value == "all") {
      this.selectAll(false);
    } else {
      this.deselect(el.value);
    }
  }

  checkEmpty() {
    let hasEntries = !this.entries || !(this.entries instanceof Array) || this.entries.length != 0;
    let isCurrentTab = this.tabs && this.tabs.activeKey == this.tabKey;
    if (this.maxEntries && hasEntries) {
      let isMaxed = this.maxEntries <= this.entries.length;
      Utility.updateContent({
        "hide-on-max-entries": {
          addClass: isMaxed ? "hidden" : "",
          removeClass: !isMaxed ? "hidden" : "",
        },
        "show-on-max-entries": {
          addClass: !isMaxed ? "hidden" : "",
          removeClass: isMaxed ? "hidden" : "",
        },
      }, this.el)
    }
    if (hasEntries) {
      this.el.classList.remove("--no-entries");
    } else {
      this.el.classList.add("--no-entries");
    }
    if (!isCurrentTab || !this.dialog) return
    if (hasEntries) {
      this.dialog.el.classList.remove("--no-entries", "--no-actions");
    } else {
      this.dialog.el.classList.add(this.type == "group" ? "--no-actions" : "--no-entries");
    }
    if (this.isSendflow) {
      this.dialog.el.setAttribute(
        "aria-label",
        `Select ${this.labels.plural}`
      );
      Utility.updateCopy(this.dialog.el, {
        title: `Select ${this.labels.plural}`,
        submit: `Add ${this.labels.plural}`,
      });
      Utility.updateContent({
        submit_btn: {
          "aria-label": `Add ${this.labels.plural}`
        },
      }, this.dialog.el);
    }
  }

  setSelectionLimit(value = false) {
    if (value) {
      this.selectionLimit = value;
    } else {
      this.selectionLimit = false;
    }
  }

  setUnselectable() {
    this.el.classList.add('--unselectable');
    this.unselectable = true;
  }
  setSelectable() {
    this.el.classList.remove('--unselectable');
    this.unselectable = false;
  }

  renderPagination() {
    const paginationEl = this.el.querySelector(".table__pagination");
    if (this.pages == 1) {
      paginationEl?.remove();
      return;
    }

    if (paginationEl) return;

    const wrapper = document.createElement("div");
    wrapper.classList.add("table__pagination");

    for (let i = 1; i <= this.pages; i++) {
      const btn = document.createElement("button");
      btn.classList.add("table__page");
      if (this.currentPage == i) {
        btn.classList.add("--active");
      }
      btn.setAttribute("data-action", "page");
      btn.value = i;
      btn.textContent = i;
      wrapper.appendChild(btn);
    }

    const placer = this.el.querySelector(".table__pagination-placer");
    if (!placer) return;
    placer.parentElement.replaceChild(wrapper, placer);
  }

  getEntry(value, field = "id") {
    if (!this.entries) return null;
    if (!value) return null;
    return this.entries.find(
      (entry) =>
        entry.hasOwnProperty(field) && String(entry[field]) === String(value)
    );
  }

  clearBlanks() {
    if (!this.entries) return;
    this.entries = [...this.data];
    let keysToClear = [];
    this.entries = this.entries.filter(entry => {
      let willRemain = !(entry.email.trim() == '' && entry.name.trim() == '');
      if (!willRemain) {
        keysToClear.push(`recipients-email-${this.timestamp}-${entry.id}`);
        keysToClear.push(`recipients-name-${this.timestamp}-${entry.id}`);
      }
      return willRemain;
    });
    this.form.clearErrors(keysToClear);
    this.originalEntries = [...this.entries];
    this.data = [...this.entries];
  }

  addEntry(entries, primary = false) {
    if (!this.entries) return;
    this.entries = [...this.data];
    if (entries instanceof Array) {
      entries.forEach((entry) => {
        if (this.maxEntries && this.entries.length >= this.maxEntries) return
        this.entries.push(entry);
      });
    } else if (!this.maxEntries || this.entries.length < this.maxEntries) {
      this.entries.push(entries);
    }
    this.originalEntries = [...this.entries];
    this.data = [...this.entries];
    if (this.endpoint && !this.header) {
      this.parent.addSource(this.endpoint, this.data, primary);
    }

    this.filterEntries();
    this.checkEmpty();
    this.search();
    this.handleChange();
    if (entries instanceof Array) {
      let ids = [];
      entries.forEach((entry) => {
        ids.push(entry.id);
      });
      this.highlightEntries(ids, "add", false, true, this.type == "recipients");
    } else {
      this.highlightEntries(
        entries.id,
        "add",
        false,
        true,
        this.type == "recipients"
      );
    }
  }

  updateEntrySilent(entries) {
    if (!(entries instanceof Array)) {
      entries = [entries];
    }
    if (!this.entries) return;
    this.entries = [...this.data];
    entries.forEach((entry) => {
      this.entries = this.entries.map((item) => {
        if (String(entry.id) === String(item.id)) {
          this.updateEntryActionsLabel(entry.id, { ...item, ...entry });
          return { ...item, ...entry }
        } else {
          return item
        }
      }
      );
    });
    this.originalEntries = [...this.entries];
    this.data = [...this.entries];
    this.handleChange();
  }

  updateEntryActionsLabel(id, entry) {
    let actionsContainer = this.el.querySelector(`[data-id="${id}"] .table__row-actions`);
    if (!actionsContainer) return
    let label = [];
    if (entry.hasOwnProperty('name') && entry.name != '') {
      label.push(entry.name);
    } else {
      if (entry.hasOwnProperty('fname') && entry.fname != '') {
        label.push(entry.fname);
      }
      if (entry.hasOwnProperty('lname') && entry.lname != '') {
        label.push(entry.lname);
      }
    }
    if (entry.hasOwnProperty('email') && entry.email != '') {
      label.push(entry.email);
    }
    actionsContainer.setAttribute('aria-label', `${this.labels.singular} ${label.join(' ')} actions`);
  }

  updateEntry(entries, primary = false) {
    if (!(entries instanceof Array)) {
      entries = [entries];
    }
    if (!this.entries) return;
    this.entries = [...this.data];
    let ids = [];
    entries.forEach((entry) => {
      ids.push(entry.id);
      this.entries = this.entries.map((item) =>
        String(entry.id) === String(item.id) ? entry : item
      );
    });
    this.originalEntries = [...this.entries];
    this.data = [...this.entries];

    if (this.hasTableInputs) {
      let keysToClear = [];
      ids.forEach((id) => {
        this.deselect(id);
        keysToClear.push(`recipients-email-${this.timestamp}-${id}`);
        keysToClear.push(`recipients-name-${this.timestamp}-${id}`);
      });
      this.form.clearErrors(keysToClear);
    } else {
      ids.forEach(id => { this.deselect(id) });
    }

    if (this.endpoint) {
      this.parent.addSource(this.endpoint, this.data, primary);
    }
    this.filterEntries();
    this.search();
    this.highlightEntries(ids, "update");
    this.handleChange();
  }

  removeEntry(ids, primary = false) {
    if (!this.entries) return;
    this.entries = [...this.data];
    ids = ids instanceof Array ? ids : [ids];

    this.entries = this.entries.filter(
      (item) => !ids.includes(String(item.id))
    );
    if (this.hasTableInputs) {
      let keysToClear = [];
      ids.forEach((id) => {
        this.deselect(id);
        keysToClear.push(`recipients-email-${this.timestamp}-${id}`);
        keysToClear.push(`recipients-name-${this.timestamp}-${id}`);
      });
      this.form.clearErrors(keysToClear);
    } else {
      ids.forEach(id => { this.deselect(id) });
    }
    this.originalEntries = [...this.entries];
    this.data = [...this.entries];
    if (this.endpoint && !this.header) {
      this.parent.addSource(this.endpoint, this.data, primary);
    }
    this.filterEntries();
    this.checkEmpty();
    this.search();
    this.handleChange();
  }

  focusEmpty() {
    let hasEntries = !this.entries || !(this.entries instanceof Array) || this.entries.length != 0;
    if (window?.app_page.wasKeyboardEvent && !hasEntries) {
      setTimeout(() => {
        let ctaEl = this.el.querySelector(`.table__no-entries button`);
        let headerEl = this.el.querySelector(`.table__no-entries [data-text-key="no-entries-title"]`);
        if (ctaEl) {
          ctaEl.focus();
        } else if (headerEl) {
          headerEl.focus();
        }
      }, 300);
      return true;
    }
    return false;
  }

  handleChange() {
    this.el.dispatchEvent(new Event("change"));
    this.onChange(this);
  }

  intersectionHandler(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("--highlight");
        this.intersectionObserver.unobserve(entry.target);
        if (!entry.target.classList.contains("--persistent")) {
          setTimeout(() => {
            entry.target.className = "";
          }, 1200);
        }
      }
    });
  }

  highlightEntries(
    ids,
    mode,
    persistent = false,
    highlight = true,
    focus = false
  ) {
    ids = ids instanceof Array ? ids : [ids];
    if (ids.length == 0) return;
    let first = null;
    ids.forEach((id) => {
      const els = this.el.querySelectorAll(
        `tr[data-id="${id}"], li[data-id="${id}"]`
      );
      els.forEach((el) => {
        if (!first && !Client.isHidden(el)) {
          first = el;
        }
        if (highlight) {
          el?.classList.add(`--${mode}`);
          if (persistent) {
            el?.classList.add(`--persistent`);
          }
        } else if (el) {
          el.className = "";
        }
        if (!this.intersectionObserver) {
          this.intersectionObserver = new IntersectionObserver(
            this.intersectionHandler.bind(this)
          );
        }
        if (el.clientHeight != 0) {
          if (highlight) {
            this.intersectionObserver.observe(el);
          } else {
            this.intersectionObserver.unobserve(el);
          }
        }
      });
      if (focus && mode != "remove" && mode != "update" && first) {
        if (window.app_page?.wasKeyboardEvent) {
          first.querySelector('input[type="text"]')?.focus();
        }
      }
      if (mode != "remove" && first && !Client.inViewport(first)) {
        Client.scrollTo(first, 400);
      }
    });
  }

  entryFilter(entry, attributes = "", index) {
    if (this.type == "contact") {
      entry.selected = this.selected.has(String(entry.id))
        ? 'checked="checked"'
        : "";
      entry.attributes = attributes;
      const has_birthday =
        entry.hasOwnProperty("birthday") && entry.birthday != "";
      const upcoming_birthday =
        entry.hasOwnProperty("birthday_up") && entry.birthday_up;
      const has_anniversary =
        entry.hasOwnProperty("anniversary") && entry.anniversary != "";
      const upcoming_anniversary =
        entry.hasOwnProperty("anniversary_up") && entry.anniversary_up;
      entry.classes = has_birthday || has_anniversary ? "" : "--no-events";
      entry.has_birthday = has_birthday ? "" : "hidden";
      entry.has_anniversary = has_anniversary ? "" : "hidden";
      entry.birthday_label = upcoming_birthday
        ? `<strong>${entry.birthday_label}</strong>`
        : entry.birthday_label;
      entry.anniversary_label = upcoming_anniversary
        ? `<strong>${entry.anniversary_label}</strong>`
        : entry.anniversary_label;
    } else if (this.type == "group") {
      entry.selected = this.selected.has(String(entry.id))
        ? 'checked="checked"'
        : "";
    } else if (this.type == "subscriptions") {
      const has_cc = entry.hasOwnProperty("has_cc") && entry.has_cc == true;
      if (has_cc) {
        entry.payment_cc = "--visible";
      }
      else {
        entry.has_cc = "False";
      }

      const paymentMap = {
        VISA: "visa",
        MC: "mastercard",
        AMEX: "amex",
        DISCOVER: "discover",
        ECP: "ecp",
        ApplePay: "applepay",
        PayPal: "paypal",
      };
      if (paymentMap.hasOwnProperty(entry.payment_method)) {
        entry[`payment_${paymentMap[entry.payment_method]}`] = "--visible";
      }
    } else if (this.type == "recipients") {
      // entry["id"] = index + 1;
    }
    return entry;
  }

  entryObserverHandler(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("--offsite");
      } else {
        entry.target.classList.add("--offsite");
      }
    });
  }

  getPaginated(entries, page) {
    return entries.slice((page - 1) * this.pagination.perPage, page * this.pagination.perPage);
  }

  renderItems(data, init = true) {
    if (this.pagination.isPaginable && this.pagination.totalPages > 1) {
      data = this.getPaginated(data, this.pagination.currentPage);
    }
    this.renderedItemsCount = data.length;
    if (this.templates.row) {
      let output = "";
      data.forEach((entry, index) => {
        output += Render.interpolateString(
          this.templates.row,
          this.entryFilter(entry, index)
        );
      });
      this.tableBody.replaceChildren(Render.fragmentFromString(output));
    }

    if (this.templates.item) {
      let outputMobile = "";
      data.forEach((entry, index) => {
        outputMobile += Render.interpolateString(
          this.templates.item,
          this.entryFilter(entry, index)
        );
      });
      this.listBody.replaceChildren(Render.fragmentFromString(outputMobile));
    }

    if (init) {
      this.initTable();
    }

    window.app_images?.init(this.el);
  }

  addSearchHighlights() {
    if (!this.isSearching) return;
    const term = this.searchBy.trim();
    let search_cells_query = [];
    this.searcheables.forEach((key) => {
      search_cells_query.push(
        `.table__cell[data-name="${key}"] .table__cell-inner`
      );
      search_cells_query.push(`.table-item__${key}`);
    });
    const cells = this.el.querySelectorAll(search_cells_query.join(", "));
    cells.forEach((cell) => {
      let inner = cell.querySelector(".table__cell-truncate");
      if (inner) {
        cell = inner;
      }
      let text = cell.textContent.replace(this.searchRegEx(term), (match) => {
        return `<strong class="table__search-match">${match}</strong>`;
      });
      cell.innerHTML = text.replaceAll(
        '</strong><strong class="table__search-match">',
        ""
      );
    });
  }

  destroy() {
    window.app_listeners?.remove("click", `table-${this.el.id}-click`);
  }

  initTable() {
    this.isLoading = false;
    this.abortController?.abort();
    this.abortController = new AbortController();
    window.app_listeners?.add(
      "click",
      `table-${this.el.id}-click`,
      this.clickHandler.bind(this)
    );
    this.watchInputs();
    this.tableReady();
    this.addSearchHighlights();
    this.selectHandler();
    this.updateHeader();
    this.el.classList.add("--table-rendered");
    this.el.classList.remove("--table-loading");
  }

  watchInputs() {
    this.hasTableInputs = this.tableBody.querySelector(
      'input:not([type="checkbox"], [type="radio"])'
    );
    if (this.hasTableInputs) {
      this.form.initFields();
      this.assignFields();
      this.form.el.addEventListener(
        "change",
        this.innerInputChange.bind(this),
        { signal: this.abortController.signal }
      );
    }
  }

  innerInputChange(e) {
    if (
      this.tableBody?.contains(e.target) ||
      this.listBody?.contains(e.target)
    ) {
      if (e.target.hasAttribute("data-id")) {
        const id = Number(e.target.getAttribute("data-id"));
        const key = e.target.getAttribute("data-key");
        const value = e.target.value;
        const entry = { id };
        entry[key] = value;
        this.updateEntrySilent(entry);
      }
    }
  }

  updateHeader() {
    let description = "";
    let length = this.entries ? this.entries.length : this.entriesCount;
    if (length == 0) {
      description = "Group with no contacts";
    } else if (length == 1) {
      description = "Group with 1 contact";
    } else {
      description = `Group with ${length} contacts`;
    }
    Utility.updateCopy(this.el, { description }, true);
  }

  tableReady() {
    this.isLoading = false;
    this.manageSearchResult();
    if (this.isFetching) {
      this.isFetching = false;
      if (this.tabs) DynamicHeight.end(this.tabs.container);
    }
    this.handleEmptyCopy();
  }

  handleEmptyCopy() {
    if (this.customEmptyTitle && this.customFilteredEmptyTitle) {
      Utility.updateCopy(this.el, {
        "no-entries-title":
          this.entries.length == 0 && this.data.length != 0
            ? this.customFilteredEmptyTitle
            : this.customEmptyTitle,
      });
    }
  }

  clickHandler(e) {
    let target = e.real_target || e.target;
    if (!this.el.contains(target)) return;

    if (target.hasAttribute("data-action")) {
      let action = target.getAttribute("data-action");
      switch (action) {
        case "sort":
          this.sort(target.name, target.value);
          break;
        case "clear":
          this.selectAll(false, true);
          break;
        case "page":
          this.changePage(Number(target.value));
          break;
        case "next-page":
          if (this.pagination.currentPage != this.pagination.totalPages) {
            this.changeDynamicPage(this.pagination.currentPage + 1);
          }
          break;
        case "prev-page":
          if (this.pagination.currentPage != 1) {
            this.changeDynamicPage(this.pagination.currentPage - 1);
          }
          break;
        case "back":
          if (this.tabs) {
            this.tabs.activate(target.value);
            if (window?.app_page.wasKeyboardEvent) {
              setTimeout(() => {
                window.app_accessibility?.focus(this.tabs.getPanel(target.value))
              }, 500)
            }
          };
          break;
        default:
          if (target.hasAttribute("data-id")) {
            this.onClick(
              e,
              action,
              { id: target.getAttribute("data-id") },
              this
            );
          } else if (
            this.selected.size != 0 &&
            ![
              "create",
              "add",
              "edit",
              "edit-group",
              "delete-group",
              "add-contacts",
            ].includes(action)
          ) {
            if (this.selected.size == 1 && action == "delete-all") {
              this.onClick(
                e,
                "delete",
                { id: Array.from(this.selected)[0] },
                this
              );
            } else {
              this.onClick(e, action, { ids: Array.from(this.selected) }, this);
            }
          } else {
            this.onClick(e, action, {}, this);
          }
          break;
      }
    } else if (target.classList.contains("--select")) {
      const id = target.parentElement.getAttribute("data-id");
      const firstButton = target
        .closest("[data-id]")
        ?.querySelector(".btn-action:not(.--red, .--ignore)");
      if (firstButton) {
        this.onClick(e, firstButton.getAttribute("data-action"), { id }, this);
      } else {
        if (this.selected.has(String(id))) {
          this.deselect(id);
        } else {
          this.select(id);
        }
      }
    }
  }

  changePage(page) {
    if (page == this.currentPage || !this.endpoint) return;
    this.el.classList.remove("--table-rendered");
    this.currentPage = page;
    let url = window.app_env.shost + this.originalEndpoint + "&page=" + page;

    this.el.setAttribute("data-get", url);
    this.el
      .querySelector(".table__page.--active")
      ?.classList.remove("--active");
    this.el
      .querySelector(`.table__page[value='${page}']`)
      ?.classList.add("--active");

    let window_url = new URL(window.location.href);
    const params = window_url.searchParams;

    if (page == 1 && params.has("page")) {
      params.delete("page");
    } else {
      params.set("page", page);
    }

    window_url.search = params.toString();
    window.history.replaceState({}, "unused", window_url);
  }

  resetSortEl() {
    if (!this.sortEl) return;
    this.sortEl.setAttribute("value", "ascending");
    this.sortEl.classList.add("--ascending");
    this.sortEl.classList.remove("--descending", "--active");
    this.sortEl.setAttribute(
      "aria-label",
      `${this.sortEl.getAttribute("data-label")}: Sort ascending`
    );
    this.sortEl.parentElement.setAttribute("aria-sort", "none");
    this.sortEl = null;
  }

  setSortEl() {
    if (!this.sortEl || this.sortOrder == "") return;
    const nextOrder =
      this.sortOrder == "ascending" ? "descending" : "ascending";
    this.sortEl.setAttribute("value", nextOrder);
    this.sortEl.classList.remove("--descending", "--ascending");
    this.sortEl.classList.add(`--${this.sortOrder}`, "--active");
    this.sortEl.setAttribute(
      "aria-label",
      `${this.sortEl.getAttribute("data-label")}: Sort ${nextOrder}`
    );
    this.fields.get(`${this.key}_sort_by`)?.setValue(this.sortBy);
    this.fields.get(`${this.key}_sort_order`)?.setValue(this.sortOrder);
    this.sortEl.parentElement.setAttribute("aria-sort", this.sortOrder);
  }

  sort(name = this.sortBy, order = this.sortOrder) {
    if (!this.entries) return;
    this.resetSortEl();
    const remap = {
      birthday: "birthday_next",
      anniversary: "anniversary_next",
    };
    const el = this.el.querySelector(`.table__sort-trigger[name="${name}"]`);
    this.sortEl = el ? el : null;
    this.sortBy = name;
    this.sortOrder = order;
    let key = remap.hasOwnProperty(name) ? remap[name] : name;
    let matchingValidData = [];
    let matchingInvalidData = [];
    let validData = [];
    let invalidData = [];
    this.entries.forEach((entry) => {
      if (
        this.isSearching &&
        entry.hasOwnProperty("is_matching") &&
        entry["is_matching"]
      ) {
        if (entry.hasOwnProperty(key) && entry[key] !== "") {
          matchingValidData.push(entry);
        } else {
          matchingInvalidData.push(entry);
        }
      } else {
        if (entry.hasOwnProperty(key) && entry[key] !== "") {
          validData.push(entry);
        } else {
          invalidData.push(entry);
        }
      }
    });

    matchingValidData = Utility.sortArrayByKey(
      matchingValidData,
      key,
      order == "descending"
    );
    validData = Utility.sortArrayByKey(validData, key, order == "descending");
    const sortedEntries = [
      ...matchingValidData,
      ...matchingInvalidData,
      ...validData,
      ...invalidData,
    ];
    this.sortedEntries = sortedEntries;
    this.pagination.totalEntries = sortedEntries.length;
    this.changeDynamicPage(1, false);
    this.renderItems(sortedEntries);
    this.setSortEl();
    this.onSort(name, order);
  }

  scrollToTop(targetElQuery = "") {
    if (this.tabs.activeKey != this.tabKey) return
    let offset = 0;
    let targetScroll = this.el.querySelector(".table__wrapper");
    if (targetElQuery) {
      targetScroll = this.el.querySelector(targetElQuery) || targetScroll;
    }
    if (!this.dialog) {
      let offsetEls = document.querySelectorAll(".header__main, .header-mobile");
      offsetEls.forEach(offsetEl => {
        if (Client.isHidden(offsetEl)) return
        offset = offsetEl.offsetHeight || 0;
      })
    }
    Client.scrollTo(
      targetScroll,
      offset, "smooth", true, this.el.closest(".dialog__wrapper")
    );
  }

  search() {
    if (!this.entries) return;
    if (this.type == 'recipients') {
      this.renderItems(this.entries);
      return
    }
    const term = this.searchBy.trim();
    if (term == "") {
      this.isSearching = false;
      this.searchResultsCount = 0;
      this.onSearch(term, this.searchResultsCount);
      this.searchComplete();
      return;
    }
    this.originalEntries = [...this.entries];
    const results = [];
    const not_matched_selected = [];
    let searcheableData = [...this.entries];
    searcheableData.forEach((entry) => {
      let tokens = [];
      this.searcheables.forEach((key) => {
        if (entry.hasOwnProperty(key) && entry[key] != "") {
          tokens.push(entry[key]);
        }
      });
      if (tokens.join(" ").match(this.searchRegEx(term))) {
        entry["is_matching"] = true;
        results.push(entry);
      } else if (this.selected.has(String(entry.id))) {
        entry["is_matching"] = false;
        not_matched_selected.push(entry);
      }
    });
    if (results.length != 0) {
      this.entries = [...results, ...not_matched_selected];
    }
    this.isSearching = true;
    this.searchResultsCount = results.length;
    this.onSearch(term, this.searchResultsCount);
    this.searchComplete();
  }

  searchComplete() {
    this.announceSearchResults = true;
    this.sort();
  }

  resultsFound() {
    if (!this.announceSearchResults) return;
    if (this.WAIannouncerTimeout) {
      clearTimeout(this.WAIannouncerTimeout);
      this.WAIannouncerTimeout = null;
    }
    if (this.isSearching && this.announceSearchResults) {
      this.WAIannouncerTimeout = setTimeout(() => {
        if (this.isSearching && this.announceSearchResults) {
          window.app_accessibility?.speak(
            this.searchResultsCount == 1
              ? `found 1 ${this.labels.singular.toLowerCase()} for ${this.searchBy
              }`
              : `found ${this.searchResultsCount
              } ${this.labels.plural.toLowerCase()} for ${this.searchBy}`,
            "assertive"
          );
          this.announceSearchResults = false;
        }
      }, 1400);
    }
    let banner = this.el.querySelector(
      ".table__announce-no-results:not(.--remove)"
    );
    if (banner) {
      if (this.announcerTimeout) {
        clearTimeout(this.announcerTimeout);
      }
      banner.classList.add("--remove");
      setTimeout(() => {
        banner.remove();
      }, 200);
    }
  }

  noResultsFound() {
    if (!this.announceSearchResults) return;
    if (this.WAIannouncerTimeout) {
      clearTimeout(this.WAIannouncerTimeout);
      this.WAIannouncerTimeout = null;
    }
    if (this.isSearching) {
      this.WAIannouncerTimeout = setTimeout(() => {
        if (this.isSearching && this.announceSearchResults) {
          window.app_accessibility?.speak(
            `No ${this.labels.plural.toLowerCase()} found for ${this.searchBy}`,
            "assertive"
          );
          this.announceSearchResults = false;
        }
      }, 1000);
    }
    let banner = this.el.querySelector(
      ".table__announce-no-results:not(.--remove)"
    );
    if (!banner) {
      const createBanner = document.createElement("div");
      createBanner.classList.add("table__announce-no-results");
      this.el
        .querySelector(".field.--cancelable-search")
        ?.appendChild(createBanner);
      banner = this.el.querySelector(
        ".table__announce-no-results:not(.--remove)"
      );
      if (!banner) return;
    }
    if (this.announcerTimeout) {
      clearTimeout(this.announcerTimeout);
      this.announcerTimeout = null;
    }
    setTimeout(() => {
      banner.classList.add("--active");
    }, 0);
    this.announcerTimeout = setTimeout(() => {
      banner.classList.add("--remove");
      setTimeout(() => {
        banner.remove();
      }, 200);
    }, 1600);
    banner.innerHTML = `No ${this.labels.plural.toLowerCase()} found for <strong>${this.searchBy}</strong>`;
  }

  manageSearchResult() {
    const count = this.renderedItemsCount || 0;
    if (this.isSearching) {
      if (this.searchResultsCount != 0) {
        Utility.updateContent({
          table_search_count: count ? `(${count})` : ""
        }, this.el)
        this.resultsFound();
      } else {
        Utility.updateContent({
          table_search_count: count ? `(${count})` : ""
        }, this.el)
        this.noResultsFound();
      }
    } else {
      this.resultsFound();
      Utility.updateContent({
        table_search_count: count ? `(${count})` : ""
      }, this.el)
    }
  }
}

export default Table;
